import React, { useState } from "react";

const BASEURL = "/.netlify/functions/dhlupload";

function asyncReadFile(file) {
  return new Promise((resolve, reject) => {
    let reader = new FileReader();

    reader.onload = () => {
      // convert content to base64 encoded string
      const result = btoa(
        new Uint8Array(reader.result).reduce(
          (data, byte) => data + String.fromCharCode(byte),
          ""
        )
      );
      resolve(result);
    };

    reader.onerror = reject;

    reader.readAsArrayBuffer(file);
  });
}

const upload = async (
  file,
  reportId,
  setLoading,
  setError,
  setCsvStatus,
  siteAccessToken
) => {
  try {
    const contents = await asyncReadFile(file);
    const resp = await fetch(
      `${BASEURL}?reportId=${reportId}&access_token=${siteAccessToken}`,
      {
        method: "POST",
        body: contents
      }
    );
    if (!resp.ok) {
      throw new Error("HTTP request failed");
    }
    const json = await resp.json();
    setCsvStatus(json);
  } catch (error) {
    setError(`Upload failed: ${error}`);
  }
  setLoading(false);
};

const DhlPortal = ({ siteAccessToken, csvStatus, setCsvStatus }) => {
  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  if (!csvStatus || !csvStatus.reportId) return null;

  if (csvStatus.state !== "NEED_CSV" && csvStatus.state !== "NEED_DHL") {
    return (
      <section className="section">
        <div className="container">
          <h2 className="is-2 subtitle">2. DHL Label Upload</h2>
          <p>Completed!</p>
          <p>
            <a
              href={`/.netlify/functions/dhlupload?reportId=${
                csvStatus.reportId
              }&access_token=${siteAccessToken}`}
              className="button is-primary"
            >
              Download PDF
            </a>
          </p>
        </div>
      </section>
    );
  }

  const onChange = e => {
    setFile(e.target.files[0]);
  };

  const onSubmit = e => {
    if (file) {
      e.preventDefault();
      setLoading(true);
      upload(
        file,
        csvStatus.reportId,
        setLoading,
        setError,
        setCsvStatus,
        siteAccessToken
      );
    }
  };

  return (
    <section className="section">
      <div className="container">
        <h2 className="is-2 subtitle">2. DHL Label Upload</h2>
        {error && (
          <div>
            <p className="danger">{error}</p>
          </div>
        )}
        <div className="file has-name">
          <label className="file-label">
            <input
              className="file-input"
              type="file"
              name="resume"
              onChange={onChange}
            />
            <span className="file-cta">
              <span className="file-icon">
                <i className="fas fa-upload" />
              </span>
              <span className="file-label">Choose a file…</span>
            </span>
            {file && <span className="file-name">{file.name}</span>}
          </label>
        </div>
        <div class="field">
          <div class="control">
            <button
              disabled={loading || !file}
              class="button is-link"
              onClick={onSubmit}
            >
              {!loading && "Submit"}
              {loading && "Uploading..."}
            </button>
          </div>
        </div>
      </div>
    </section>
  );
};

export default DhlPortal;
