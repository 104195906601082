import React, { useState, useEffect } from "react";

const BASEURL = "/.netlify/functions/dhlcsv";

export const getCsvStatus = async (
  setCsvStatus,
  setCsvError,
  siteAccessToken
) => {
  try {
    const res = await fetch(
      `${BASEURL}?status&access_token=${siteAccessToken}`
    );
    if (!res.ok) {
      setCsvError("Failed to get CSV Status");
    } else {
      const json = await res.json();
      setCsvError(null);
      setCsvStatus(json);
    }
  } catch (error) {
    setCsvError("Error: " + error);
  }
};

const createNewCsv = async (setCsvStatus, setCsvError, siteAccessToken) => {
  try {
    const res = await fetch(`${BASEURL}?access_token=${siteAccessToken}`, {
      method: "POST"
    });
    if (!res.ok) {
      setCsvError("Failed to create CSV");
    } else {
      const json = await res.json();
      setCsvStatus(json);
    }
  } catch (error) {
    setCsvError("Error: " + error);
  }
};

const CsvDownload = ({ reportId, siteAccessToken }) => (
  <div>
    <p>CSV is ready for download.</p>
    <p>
      <a
        href={`${BASEURL}?reportId=${reportId}&access_token=${siteAccessToken}`}
        className="button is-primary"
      >
        Download
      </a>
    </p>
  </div>
);

const CsvCreateButtom = ({ setCsvStatus, setCsvError, siteAccessToken }) => {
  const [loading, setLoading] = useState(false);
  return (
    <p>
      <button
        className="button is-primary"
        disabled={loading}
        onClick={e => {
          e.preventDefault();
          setLoading(true);
          createNewCsv(setCsvStatus, setCsvError, siteAccessToken);
        }}
      >
        {!loading && "Create a new CSV"}
        {loading && "Creating CSV..."}
      </button>
    </p>
  );
};

const CsvPortal = ({
  siteAccessToken,
  csvStatus,
  csvError,
  setCsvStatus,
  setCsvError
}) => {
  useEffect(() => {
    if (!csvStatus && siteAccessToken) {
      setCsvError(null);
      getCsvStatus(setCsvStatus, setCsvError, siteAccessToken);
    }
  }, [siteAccessToken]);

  const reportId = csvStatus && csvStatus.reportId;

  return (
    <section className="section">
      <div className="container">
        <h2 className="is-2 subtitle">1. CSV Export for DHL</h2>
        {!csvStatus && !csvError && <p>Loading CSV status...</p>}
        {csvError && <p className="">Failed to load CSV status: {csvError}</p>}
        {reportId && (
          <CsvDownload reportId={reportId} siteAccessToken={siteAccessToken} />
        )}
        {!reportId && csvStatus && (
          <CsvCreateButtom
            siteAccessToken={siteAccessToken}
            setCsvStatus={setCsvStatus}
            setCsvError={setCsvError}
          />
        )}
      </div>
    </section>
  );
};

export default CsvPortal;
