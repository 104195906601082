import React, { useState, useEffect } from "react";
import { getCsvStatus } from "./CsvPortal";

const AeLabelPortal = ({ csvStatus, setCsvStatus, siteAccessToken }) => {
  const reportId = csvStatus && csvStatus.reportId;
  if (!reportId) {
    return null;
  }

  const [error, setError] = useState(null);
  const needLabels = csvStatus.state === "NEED_LABELS";

  useEffect(() => {
    if (csvStatus.state === "NEED_LABELS") {
      console.log("starting refresher");
      const intervalId = setInterval(
        getCsvStatus,
        3000,
        setCsvStatus,
        setError,
        siteAccessToken
      );
      const clearFn = () => clearInterval(intervalId);
      return clearFn;
    }
  }, [csvStatus]);

  return (
    <section className="section">
      <div className="container">
        <h2 className="is-2 subtitle">3. AE Label Download</h2>
        {error && (
          <p className="is-danger">Failed to get A+E label status: {error}</p>
        )}
        {needLabels && "Labels are being generated..."}
      </div>
    </section>
  );
};

export default AeLabelPortal;
