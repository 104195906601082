import React, { useState, useEffect } from "react";

const BASEURL = "/.netlify/functions/labelprints";

export const getLabelprints = async (
  setPrints,
  setPrintsError,
  siteAccessToken
) => {
  try {
    const res = await fetch(`${BASEURL}?access_token=${siteAccessToken}`);
    if (!res.ok) {
      setPrintsError("Failed to get labelprints listing");
    } else {
      const json = await res.json();
      setPrintsError(null);
      setPrints(json);
    }
  } catch (error) {
    setPrintsError("Error: " + error);
  }
};

const Row = ({
  ftime,
  ctime,
  labelprintId,
  csvExists,
  dhlExists,
  aeExists,
  siteAccessToken
}) => {
  const params = `reportId=${labelprintId}&access_token=${siteAccessToken}`;

  const csvUrl = csvExists ? (
    <a target="_blank" href={`/.netlify/functions/dhlcsv?${params}`}>
      Download
    </a>
  ) : null;
  const dhlUrl = dhlExists ? (
    <a target="_blank" href={`/.netlify/functions/dhlupload?${params}`}>
      Download
    </a>
  ) : null;
  const aeUrl = aeExists ? (
    <a
      target="_blank"
      href={`/.netlify/functions/download?${params}&kind=aelabels`}
    >
      Download
    </a>
  ) : null;
  const inventoryUrl = aeExists ? (
    <a
      target="_blank"
      href={`/.netlify/functions/download?${params}&kind=inventory`}
    >
      Download
    </a>
  ) : null;
  const ordersheetsPdf = aeExists ? (
    <a
      target="_blank"
      href={`/.netlify/functions/download?${params}&kind=ordersheets`}
    >
      Download
    </a>
  ) : null;

  return (
    <tr>
      <td>{labelprintId}</td>
      <td>{ftime || ctime}</td>
      <td>{csvUrl}</td>
      <td>{dhlUrl}</td>
      <td>{aeUrl}</td>
      <td>{inventoryUrl}</td>
      <td>{ordersheetsPdf}</td>
    </tr>
  );
};

const LeTable = ({ rows, siteAccessToken }) =>
  rows && (
    <table className="table">
      <thead>
        <th>ID</th>
        <th>Timestamp</th>
        <th>CSV</th>
        <th>DHL Labels</th>
        <th>A+E Labels</th>
        <th>Inventory</th>
        <th>Ordersheets</th>
      </thead>
      <tbody>
        {rows.map(row => (
          <Row
            {...row}
            key={row.labelprintId}
            siteAccessToken={siteAccessToken}
          />
        ))}
      </tbody>
    </table>
  );

const LabelprintsTable = ({ csvStatus, siteAccessToken }) => {
  const [prints, setPrints] = useState(null);
  const [printsError, setPrintsError] = useState(null);

  useEffect(() => {
    if (siteAccessToken)
      getLabelprints(setPrints, setPrintsError, siteAccessToken);
  }, [csvStatus, siteAccessToken]);

  return (
    <section className="section">
      <div className="container">
        <h2 className="is-2 subtitle">Existing labelprints</h2>
        {printsError && <p className="is-danger">{printsError}</p>}
        {prints && <LeTable rows={prints} siteAccessToken={siteAccessToken} />}
      </div>
    </section>
  );
};

export default LabelprintsTable;
