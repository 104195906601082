import React, { useState } from "react";
import CsvPortal from "../organisms/CsvPortal";
import DhlPortal from "../organisms/DhlPortal";
import AeLabelPortal from "../organisms/AeLabelPortal";
import LabelprintsTable from "../organisms/LabelprintsTable";
import QS from "query-string";

const windowAccessToken = () => QS.parse(window.location.search).access_token;

const LabelgenWeb = props => {
  const [csvStatus, setCsvStatus] = useState(undefined);
  const [csvError, setCsvError] = useState(null);
  const [siteAccessToken, setSiteAccessToken] = useState("");

  if (siteAccessToken === "" && windowAccessToken() !== "") {
    setSiteAccessToken(windowAccessToken());
  }

  const state = {
    csvStatus,
    setCsvStatus,
    csvError,
    setCsvError,
    siteAccessToken
  };

  return (
    <div>
      <nav
        className="navbar"
        role="navigation"
        aria-label="main navigation"
        style={{ borderBottom: "1px solid grey" }}
      >
        <div className="navbar-brand">Label Generator</div>
        <div className="navbar-menu">
          <div className="navbar-start" />
          <div className="navbar-end">
            <div className="navbar-item">
              <input
                className="input is-rounded"
                type="text"
                placeholder="Access Token"
                style={{ maxWidth: "14rem" }}
                value={siteAccessToken}
                onChange={ev => setSiteAccessToken(ev.target.value)}
              />
            </div>
          </div>
        </div>
      </nav>
      <section>
        <div className="container" />
      </section>
      <LabelprintsTable
        siteAccessToken={siteAccessToken}
        csvStatus={csvStatus}
      />
      <CsvPortal {...state} />
      <DhlPortal {...state} />
      <AeLabelPortal {...state} />
    </div>
  );
};

export default LabelgenWeb;
